import { SponsorshipPackagesComponent } from './routes/sponsorship-packages/sponsorship-packages.component';
import { Routes } from '@angular/router';

import { AppComponent } from './app.component';

import { awardsRoutes } from './routes/awards/awards.routes';
import { AuthGuard } from './guards/auth-guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./shared/layout/layout.component').then((m) => m.LayoutComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./routes/homepage/homepage.component').then(
            (m) => m.HomepageComponent
          ),
      },

      {
        path: 'blog',
        loadComponent: () =>
          import('./routes/blog/blog.component').then((m) => m.BlogComponent),
      },
      {
        path: 'sponsor',
        loadComponent: () =>
          import(
            './routes/sponsorship-packages/sponsorship-packages.component'
          ).then((m) => m.SponsorshipPackagesComponent),
      },
      {
        path: 'sponsors',
        loadComponent: () =>
          import('./routes/sponsors/sponsors.component').then(
            (m) => m.SponsorsComponent
          ),
      },
      {
        path: 'faqs',
        loadComponent: () =>
          import('./routes/faqs/faqs.component').then((m) => m.FaqsComponent),
      },
      {
        path: 'awards',
        children: awardsRoutes,
      },
      {
        path: 'nominate',
        loadComponent: () =>
          import('./routes/nominate/nominate.component').then(
            (m) => m.NominateComponent
          ),
      },
      {
        path: 'gallery',
        loadComponent: () =>
          import('./routes/gallery-list/gallery-list.component').then(
            (m) => m.GalleryListComponent
          ),
      },

      {
        path: 'gallery/:year',
        loadComponent: () =>
          import('./routes/gallery/photo-gallery/photo-gallery.component').then(
            (m) => m.PhotoGalleryComponent
          ),
      },

      {
        path: 'shortlist',
        children: [
          {
            path: 'photo-upload',
            children: [
              {
                path: ':eventId',
                loadComponent: () =>
                  import(
                    './routes/shortlist/photo-upload/photo-upload.component'
                  ).then((m) => m.PhotoUploadComponent),
              },
            ],
          },
          {
            path: 'confirm-attendance',
            children: [
              {
                path: ':eventId',
                loadComponent: () =>
                  import(
                    './routes/shortlist/attendance-confirmation/attendance-confirmation.component'
                  ).then((m) => m.AttendanceConfirmationComponent),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./routes/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'system',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./shared/system-layout/system-layout.component').then(
        (m) => m.SystemLayoutComponent
      ),
    children: [
      {
        path: 'dashboard',

        loadComponent: () =>
          import('./routes/system/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          ),
      },
      {
        path: 'nominations',

        loadComponent: () =>
          import('./routes/system/nominations/nominations.component').then(
            (m) => m.NominationsComponent
          ),
      },
      {
        path: 'year-on-year',

        loadComponent: () =>
          import(
            './routes/system/year-on-year-comparison/year-on-year-comparison.component'
          ).then((m) => m.YearOnYearComparisonComponent),
      },
      {
        path: 'winners',

        loadComponent: () =>
          import('./routes/system/winners/winners.component').then(
            (m) => m.WinnersComponent
          ),
      },
      {
        path: 'sponsorship-packages',

        loadComponent: () =>
          import(
            './routes/system/sponsorship-packages/sponsorship-packages.component'
          ).then((m) => m.SponsorshipPackagesComponent),
      },
      {
        path: 'sponsors',

        loadComponent: () =>
          import('./routes/system/sponsors/sponsors.component').then(
            (m) => m.SponsorsComponent
          ),
      },
    ],
  },
];
