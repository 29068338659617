<div class="holding-page">
  <div class="container">
    <div class="row">
      <div class="col-md-2 text-center">
        <img class="mt-4" src="../../../assets/developher-logo-master 1.png" />
      </div>
      <div class="col-md-2 offset-md-8 text-white text-center">
        <div class="mt-md-4">
          <i
            class="fa-brands fa-x-twitter me-3 pointer scale"
            (click)="goToSocial(SocialType.X)"
          ></i>
          <i
            class="fa-brands fa-instagram me-3 pointer scale"
            (click)="goToSocial(SocialType.Instagram)"
          ></i>
          <i
            class="fa-brands fa-linkedin me-3 pointer scale"
            (click)="goToSocial(SocialType.LinkedIn)"
          ></i>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-7 mx-auto text-center">
        <h1 class="text-uppercase mt-4">Coming soon</h1>
        <p class="mt-3">
          Our new site launches very soon. Join our mailing list to be the first
          to know about our plans for our 10th anniversary next year.
        </p>

        <div class="row mt-4">
          <div class="col-lg-12 col-xl-12">
            <div class="btn btn-outline-white" (click)="openMailingListModal()">
              Join our mailing list<i class="ms-2 fa-regular fa-envelope"></i>
            </div>
          </div>
          @if(false){
          <div class="col-lg-6 col-xl-5 offset-xl-1">
            <div class="btn btn-outline-white" (click)="openMailingListModal()">
              Join our mailing list<i class="ms-2 fa-regular fa-envelope"></i>
            </div>
          </div>

          <div class="col-lg-6 col-xl-5 mt-2 mt-lg-0">
            <div class="btn btn-outline-white" (click)="openOldSite()">
              Visit old site <i class="ms-2 fa-solid fa-chevron-right"></i>
            </div>
          </div>
          }
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-md-3 offset-md-9 text-white text-center text-lg-left tabs-margin"
      >
        <span
          (click)="selectTab(HoldingPageTab.Winners)"
          [class.text-underline]="selectedTab() == HoldingPageTab.Winners"
          class="text-white"
        >
          Winners 2024
        </span>
        <span
          (click)="selectTab(HoldingPageTab.Gallery)"
          [class.text-underline]="selectedTab() == HoldingPageTab.Gallery"
          class="text-white ms-3"
        >
          Gallery
        </span>
      </div>
    </div>
  </div>

  <div class="row mt-4 g-0">
    <div class="col-md-12">
      @if(selectedTab() == HoldingPageTab.Winners){
      <app-winners-carousel @fadeIn></app-winners-carousel>
      } @if(selectedTab() == HoldingPageTab.Gallery){
      <app-gallery @fadeIn></app-gallery>
      }
    </div>
  </div>
</div>
<app-diamonds></app-diamonds>
