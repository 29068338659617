<nav
  id="nav"
  class="navbar navbar-expand-md py-3"
  (scroll)="onScroll($event)"
  [@navView]="state"
>
  <div class="container-fluid">
    <div class="col-4 col-md-2 d-flex justify-content-center">
      <a class="navbar-brand me-0 ms-5 ms-md-0" href="/"
        ><img width="200" src="./assets/developher-logo-master 1.png"
      /></a>
    </div>

    <button
      class="navbar-toggler border-0"
      type="button"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggle()"
    >
      <span>
        <i class="fa fa-lg fa-bars text-primary"></i>
      </span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <li class="nav-item mx-3 me-md-4">
          <!-- Has to be an href as blog does not load otherwise -->
          <a
            href="/blog"
            class="nav-link open-main fw-bold"
            [ngClass]="
              router.url.includes('/blog') ? 'text-primary' : 'text-white'
            "
          >
            Blog
          </a>
        </li>
        <li class="nav-item mx-3 mx-md-4">
          <a
            [routerLink]="'/sponsors'"
            [routerLinkActive]="'active-tab-distributor'"
            class="nav-link open-main fw-bold"
            [ngClass]="
              router.url.includes('/sponsors') ? 'text-primary' : 'text-white'
            "
          >
            Our sponsors
          </a>
        </li>
        <li class="nav-item mx-3 mx-md-4">
          <a
            [routerLink]="'/awards'"
            [routerLinkActive]="'active-tab-distributor'"
            class="nav-link open-main fw-bold"
            [ngClass]="
              router.url.includes('/awards') ? 'text-primary' : 'text-white'
            "
          >
            Awards
          </a>
        </li>
        <li class="nav-item mx-3 mx-md-4">
          <a
            [routerLink]="'/faqs'"
            [routerLinkActive]="'active-tab-distributor'"
            class="nav-link open-main fw-bold"
            [ngClass]="
              router.url.includes('/faqs') ? 'text-primary' : 'text-white'
            "
          >
            FAQs
          </a>
        </li>
        <li class="nav-item mx-3 mx-md-4">
          <a
            [routerLink]="'/gallery'"
            [routerLinkActive]="'active-tab-distributor'"
            class="nav-link open-main fw-bold"
            [ngClass]="
              router.url.includes('/gallery') ? 'text-primary' : 'text-white'
            "
          >
            Gallery
          </a>
        </li>
      </ul>
      <div class="d-flex ms-md-auto me-lg-5 mx-2 mx-md-0">
        <button class="btn btn-dark btn-medium me-3" [routerLink]="'/nominate'">
          Nominate
        </button>
        <button
          class="btn btn-outline-primary btn-medium me-lg-4"
          [routerLink]="'sponsor'"
        >
          Become a sponsor
        </button>
      </div>
    </div>
  </div>
</nav>

<div
  id="menuPartial"
  class="sidenav d-lg-none d-md-block d-block"
  [ngClass]="open ? 'openSlider' : ''"
>
  <div class="menu-partial-container info-container px-3">
    <div class="d-flex justify-content-between ps-3 pe-2">
      <div>
        <a class="navbar-brand me-0" href="/"
          ><img height="auto" width="111" src="/assets/dh-new.PNG"
        /></a>
      </div>
      <div (click)="toggle()">
        <i class="fa fa-times text-primary fa-lg"></i>
      </div>
    </div>

    <div class="mt-4">
      <ul class="hover-li list-unstyled condensed-md">
        <li class="nav-item mx-3">
          <a
            [routerLink]="'blog'"
            (click)="toggle()"
            class="text-primary nav-link text-bold"
          >
            Blog
          </a>
        </li>
        <li class="nav-item mx-3">
          <a
            [routerLink]="'about'"
            (click)="toggle()"
            class="text-primary nav-link text-bold"
          >
            About
          </a>
        </li>
        <li class="nav-item mx-3">
          <a [routerLink]="'/nominate'" class="text-primary nav-link text-bold">
            Nominate
          </a>
        </li>
        <li class="nav-item mx-3">
          <a (click)="sponsor()" class="text-primary nav-link text-bold">
            Sponsor
          </a>
        </li>
        <li class="nav-item mx-3">
          <a [routerLink]="'/gallery'" class="text-primary nav-link text-bold">
            Gallery
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
