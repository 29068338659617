import { Component, inject, OnInit, output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { SideBarItem } from './sidebar-item.model';
import { NgClass } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgClass],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  authService = inject(AuthService);
  router = inject(Router);
  toggle = output<boolean>();

  sidebarNavItems: SideBarItem[] = [
    {
      title: 'Dashboard',
      icon: 'fa fa-gauge',
      link: '/dashboard',
      selected: true,
    },
    {
      title: 'Nominations',
      icon: 'fa fa-list-ul',
      link: '/nominations',
      selected: false,
    },
    {
      title: 'Comparison',
      icon: 'fa fa-code-compare',
      link: '/year-on-year',
      selected: false,
    },
    {
      title: 'Winners',
      icon: 'fa fa-trophy',
      link: '/winners',
      selected: false,
    },
  ];

  ngOnInit() {
    let url = this.router.url;
    this.sidebarNavItems.forEach((i) => {
      i.selected = i.link === url;
    });
  }
  logout() {
    this.authService.logOut();
    this.router.navigate(['/login']);
  }

  getName() {
    //TODO - get from auth
    return 'Vickie Allen';
  }

  selectItem(item: SideBarItem) {
    this.sidebarNavItems.forEach((i) => (i.selected = false));
    item.selected = true;
    this.router.navigate([item.link]);
    this.toggle.emit(true);
  }
}
