<div class="bg-hero">
  <div class="hero-padding">
    <div class="align-items-center justify-content-center">
      <div class="mb-4 mt-5 pt-4">
        <h1 class="text-white text-center">DevelopHER Awards 2025</h1>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="align-items-center justify-content-center text-center">
    <div class="mb-4 mt-5 pt-4">
      <h2>About the DevelopHER Awards</h2>
      <div class="col-lg-8 offset-lg-2">
        <p class="row mt-2 mx-4"></p>
        <p>
          The DevelopHER Awards is an annual not for profit awards ceremony
          recognising women who work in the technology sector in East Anglia.
        </p>
        <p>
          Founded in 2015, the awards celebrates it's 10th anniversary in 2025,
          returning to Norwich, and cannot wait to celebrate with you all once
          again.
        </p>
        <p>
          The awards were founded and are run by Vickie Allen, who regularly
          attends and speaks at events promoting gender equality and is
          passionate about creating a better, more equal future in the
          technology industry. The DevelopHER awards aims to raise the profile
          of women in technology in East Anglia, creating role models to inspire
          the next generation to become developers, technicians, project
          managers, testers and digital experts.
        </p>
        <p>
          If you and your company would like to get involved we’d love to hear
          from you. You can nominate a woman working in tech, sponsor a package,
          attend the event or volunteer and get involved.
        </p>
      </div>
    </div>

    <div class="mb-4 mt-5 pt-4">
      <h2 class="carousel-margin">Winners 2024</h2>
      <app-winners-carousel @fadeIn></app-winners-carousel>
    </div>
  </div>
</div>
