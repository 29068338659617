<div class="modal-content">
  <div class="modal-body text-center">
    <img
      width="250"
      class="mt-4"
      src="assets/DevelopHERLogo.png"
      alt="DevelopHER Awards"
    />
    <div id="mc_embed_shell">
      <link
        href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
        rel="stylesheet"
        type="text/css"
      />
      <style type="text/css">
        #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px;}
        /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
           We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
      </style>
      <div id="mc_embed_signup">
        <form
          action="https://developherawards.us11.list-manage.com/subscribe/post?u=1b0040927a91b48d84a7816e9&amp;id=ae728e2703&amp;f_id=0047d9e3f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_self"
          novalidate=""
        >
          <div id="mc_embed_signup_scroll">
            <h2 class="text-center">Subscribe to our email list</h2>
            <div class="indicates-required">
              <span class="asterisk">*</span> indicates required
            </div>
            <div class="mc-field-group">
              <label for="mce-EMAIL"
                >Email Address <span class="asterisk">*</span></label
              ><input
                type="email"
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                required=""
                value=""
              />
            </div>
            <div class="mc-field-group">
              <label for="mce-FNAME">First Name </label
              ><input
                type="text"
                name="FNAME"
                class="text"
                id="mce-FNAME"
                value=""
              />
            </div>
            <div class="mc-field-group">
              <label for="mce-LNAME">Last Name </label
              ><input
                type="text"
                name="LNAME"
                class="text"
                id="mce-LNAME"
                value=""
              />
            </div>
            <div id="mce-responses" class="clear foot">
              <div
                class="response"
                id="mce-error-response"
                style="display: none"
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style="display: none"
              ></div>
            </div>
            <div aria-hidden="true" style="position: absolute; left: -5000px">
              /* real people should not fill this in and expect good things - do
              not remove this or risk form bot signups */
              <input
                type="text"
                name="b_1b0040927a91b48d84a7816e9_ae728e2703"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="optionalParent">
              <div class="clear foot">
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="button"
                  value="Subscribe"
                />
                <p style="margin: 0px auto">
                  <a
                    href="http://eepurl.com/iW2pSQ"
                    title="Mailchimp - email marketing made easy and fun"
                    ><span
                      style="
                        display: inline-block;
                        background-color: transparent;
                        border-radius: 4px;
                      "
                      ><img
                        class="refferal_badge"
                        src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                        alt="Intuit Mailchimp"
                        style="
                          width: 220px;
                          height: 40px;
                          display: flex;
                          padding: 2px 0px;
                          justify-content: center;
                          align-items: center;
                        " /></span
                  ></a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>

      <p class="text-dark text-center">
        We hate spam. You can unsubscribe at any time.
      </p>
    </div>
  </div>
</div>
