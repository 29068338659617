import { Component } from '@angular/core';
import { WinnersCarouselComponent } from '../holding-page/winners-carousel/winners-carousel.component';
import { fadeIn } from '../../shared/animations/animations';
@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [WinnersCarouselComponent],
  animations: [fadeIn],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss',
})
export class HomepageComponent {}
