import { AwardResolver } from './resolvers/award.resolver';

export const awardsRoutes = [
  {
    path: '',
    loadComponent: () =>
      import('./awards.component').then((m) => m.AwardsComponent),
  },
  {
    path: ':awardName',
    resolve: {
      award: AwardResolver,
    },
    loadComponent: () =>
      import('./award/award.component').then((m) => m.AwardComponent),
  },
];
