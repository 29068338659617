import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  afterNextRender,
} from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';
import { Autoplay } from 'swiper/modules';
@Directive({
  selector: '[appSwiper]',
  standalone: true,
})
export class SwiperDirective {
  @Input() config?: SwiperOptions;

  constructor(private el: ElementRef<SwiperContainer>) {
    afterNextRender(() => {
      const swiperParams = {
        autoplay: {
          delay: 1,
          disableOnInteraction: true,
        },

        centeredSlides: true,

        speed: 2000,
        loop: true,
        slideToClickedSlide: true,
        initialSlide: 6,
        spaceBetween: 0,
        watchOverflow: true,
        cssMode: false,
        breakpoints: {
          100: {
            slidesPerView: 1.5,
          },
          598: {
            slidesPerView: 2.5,
          },
          984: {
            slidesPerView: 3.5,
          },
          1394: {
            slidesPerView: 5.5,
          },
          1880: {
            slidesPerView: 7.5,
          },
        },
        modules: [Autoplay],
      };

      Object.assign(this.el.nativeElement, swiperParams);
      this.el.nativeElement.initialize();
    });
  }
}
