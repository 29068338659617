import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  let authService = inject(AuthService);
  // Clone the request and add the authorization header
  let authReq = request;
  if (request.url.startsWith(environment.apiUrl)) {
    if (!!authService.accessToken()) {
      authReq = authReq.clone({
        withCredentials: true,
        headers: authReq.headers.set(
          'Authorization',
          `Bearer ${authService.accessToken()}`
        ),
      });
    }
  }

  return next(authReq);
};
