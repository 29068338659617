<div class="container-fluid d-lg-none">
  <div class="d-flex justify-content-between">
    <a class="navbar-brand me-0 ms-md-0 p-2" href="/"
      ><img src="../../../assets/developher-logo-master 1.png" height="40"
    /></a>

    <button
      class="navbar-toggler border-0"
      type="button"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggle()"
    >
      <span>
        <i class="fa fa-lg fa-bars text-white"></i>
      </span>
    </button>
  </div>
</div>
<div
  id="menuPartial"
  class="sidenav d-lg-none d-md-block d-block bg-dark"
  [ngClass]="open() ? 'openSlider' : ''"
>
  <app-sidebar (toggle)="toggle()"></app-sidebar>
</div>

<div class="container-fluid">
  <div class="row flex-xl-nowrap px-0">
    <div class="d-none d-lg-block col-xl-3 ontop" style="width: 352px">
      <app-sidebar></app-sidebar>
    </div>
    <div
      class="col-xl-9 col-lg-6 flex-fill col-12 bg-white px-xl-5 px-lg-0 px-3"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
