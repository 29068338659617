import { Component, inject } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, RouterOutlet } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';

@Component({
  selector: 'app-system-layout',
  standalone: true,
  imports: [SidebarComponent, RouterOutlet],
  templateUrl: './system-layout.component.html',
  styleUrl: './system-layout.component.scss',
})
export class SystemLayoutComponent {}
