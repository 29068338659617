import { Router, RouterOutlet } from '@angular/router';
import { Component } from '@angular/core';

import { HeaderComponent } from './header/header.component';
import { environment } from '../../../environments/environment';
import { HoldingPageComponent } from '../../routes/holding-page/holding-page.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, HoldingPageComponent],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  get showHoldingPage() {
    return environment.holdingPage;
  }
}
