<div
  class="d-flex flex-column align-items-center align-items-sm-start px-4 py-md-5 pt-2 pb-5 min-vh-100 w-100 text-body-2 nav-bar"
>
  <img src="../../../../assets/developher-logo-master 1.png" />

  <ul
    id="menu"
    class="nav nav-pills flex-column mb-sm-2 pb-md-5 p-3 border-bottom align-items-center align-items-sm-start w-100 px-3"
  >
    @for (item of sidebarNavItems; track item) {
    <li class="nav-item w-100">
      <a
        [class.text-primary]="item.selected"
        [class.fw-semibold]="item.selected"
        class="nav-link align-middle px-0 pointer w-100"
        (click)="selectItem(item)"
      >
        <i [ngClass]="item.icon" class="me-3"></i>
        <span class="ms-1 d-inline">{{ item.title }}</span>
      </a>
    </li>
    }
  </ul>
  <ul
    class="nav nav-pills flex-column mb-sm-2 pt-md-3 py-3 align-items-center align-items-sm-start w-100 px-3"
  >
    <li class="nav-item w-100">
      <p class="d-flex align-items-center mb-0">
        <a
          class="nav-link user align-middle px-0 pointer w-100 fw-semibold"
          [attr.aria-expanded]="true"
          aria-controls="collapseBasic"
        >
          <span><i class="fa fa-user-circle me-3"></i>{{ getName() }}</span>
        </a>
      </p>
    </li>
    <li class="nav-item w-100">
      <p class="d-flex align-items-center mb-0">
        <a
          (click)="logout()"
          class="nav-link user align-middle px-0 pointer w-100 fw-semibold"
          [attr.aria-expanded]="true"
          aria-controls="collapseBasic"
        >
          <span
            ><i class="fa fa-person-walking-arrow-right me-3"></i>Logout</span
          >
        </a>
      </p>
    </li>
  </ul>
</div>
