import { NgClass } from '@angular/common';
import {
  Component,
  TemplateRef,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';

@Component({
  selector: 'app-system-layout',
  standalone: true,
  imports: [SidebarComponent, RouterOutlet, NgClass, RouterLink],
  templateUrl: './system-layout.component.html',
  styleUrl: './system-layout.component.scss',
})
export class SystemLayoutComponent {
  open = signal(false);
  toggle() {
    this.open.set(!this.open());
  }
}
