<swiper-container appSwiper #swiper class="swiper-margin">
  @for(winner of winners; track winner){
  <swiper-slide>
    <div class="slider-container">
      <img
        class="slider-image"
        src="{{ winner.image }}"
        alt="{{ winner.name }}"
      />
      <p class="text-uppercase text-medium mb-0 mt-3 fw-bold">
        {{ winner.name }}
      </p>
      <p class="text-medium">{{ winner.award }}</p>
    </div>
  </swiper-slide>

  }
</swiper-container>
