import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  ViewChild,
  viewChild,
} from '@angular/core';
import { SwiperContainer, register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../../directives/swiper.directive';

export interface Winner {
  name: string;
  award: string;
  image: string;
}
@Component({
  selector: 'app-winners-carousel',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [SwiperDirective],
  templateUrl: './winners-carousel.component.html',
  styleUrl: './winners-carousel.component.scss',
})
export class WinnersCarouselComponent implements AfterViewInit {
  swiper = viewChild<ElementRef<SwiperContainer>>('swiper');

  standardSwiperConfig: SwiperOptions = {
    autoplay: {
      delay: 1,
      disableOnInteraction: true,
    },
    centeredSlides: true,

    speed: 800,
    // autoplay: {
    //   delay: 2000,
    //   disableOnInteraction: true,
    // },
    // centeredSlides: true,
    // slideActiveClass: 'swiper-slide-active',
    // speed: 800,
    // loop: false,
    // spaceBetween: 1,
    //    initialSlide: 3,
    // watchOverflow: true,
    // slidesPerView: 5,
  };
  winners: Winner[] = [
    {
      name: 'Caitlin Owen',
      award: 'Apprentice',
      image: '../../../assets/winners-2024/apprentice.png',
    },
    {
      name: 'Steph Darrah',
      award: 'Best Newcomer',
      image: '../../../assets/winners-2024/best-newcomer.png',
    },
    {
      name: 'Sophie Howard',
      award: 'Digital Marketer',
      image: '../../../assets/winners-2024/digital-marketer.png',
    },
    {
      name: 'Morrigan Pickford',
      award: 'Emerging Talent',
      image: '../../../assets/winners-2024/emerging-talent.png',
    },
    {
      name: 'Be Peters',
      award: 'Eye for design',
      image: '../../../assets/winners-2024/eye-for-design.png',
    },
    {
      name: 'Kate Killick',
      award: 'Game Development',
      image: '../../../assets/winners-2024/game-dev.png',
    },
    {
      name: 'Jessica Keen',
      award: 'Innovation',
      image: '../../../assets/winners-2024/innovation.png',
    },
    {
      name: 'Louise Goodthorp',
      award: 'Inspiration',
      image: '../../../assets/winners-2024/inspiration.png',
    },
    {
      name: 'Abi Bremner',
      award: 'Product Management',
      image: '../../../assets/winners-2024/product-management.png',
    },
    {
      name: 'Thuria Wenbar',
      award: 'Tech for Good',
      image: '../../../assets/winners-2024/tech-for-good.png',
    },
    {
      name: 'Kayleigh Hall',
      award: 'Tech Star',
      image: '../../../assets/winners-2024/tech-star.png',
    },
    {
      name: 'Zoey Turner',
      award: 'Tester',
      image: '../../../assets/winners-2024/tester.png',
    },
    {
      name: 'Georgie Rayner-Madden',
      award: 'Unsung Hero',
      image: '../../../assets/winners-2024/unsung-hero.png',
    },
    {
      name: 'Moyosore Ale',
      award: 'User Experience',
      image: '../../../assets/winners-2024/user-experience.png',
    },
  ];

  constructor() {
    register();
  }

  ngAfterViewInit(): void {
    //this.swiper()?.nativeElement.slideTo(3);
    this.swiper()?.nativeElement.swiper?.slideTo(3);
    this.swiper()?.nativeElement.setAttribute('autoplay-delay', '2000');
  }
}
