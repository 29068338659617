import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';

import { Injectable, inject } from '@angular/core';

import { map, tap } from 'rxjs/operators';
import { Awards } from '../../../shared/model/api';

@Injectable({
  providedIn: 'root',
})
export class AwardResolver {
  private apollo = inject(Apollo);
  constructor() {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.getAward(route.params['awardName']);
  }

  private getAward(name: string): Observable<Awards> {
    const awardQuery = gql`
    
    query GetAwards {
        awards(where: {name: {_ilike: "${name}"}}) {
          name
        }
      }
      `;

    return this.apollo
      .query<{ awards: Awards[] }>({
        query: awardQuery,
        errorPolicy: 'ignore',
      })
      .pipe(
        map((result) => result.data.awards[0]),
        tap(console.log)
      );
  }
}
