import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';
import { ModalModule } from 'ngx-bootstrap/modal';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { graphqlProvider } from './graphql.provider';
import { authInterceptor } from './interceptors/auth.interceptor';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideClientHydration(),
    provideAnimations(),
    importProvidersFrom([
      ModalModule.forRoot(),
      TypeaheadModule.forRoot(),
      PhotoGalleryModule.forRoot({
        defaultOptions: {
          arrowEl: true,
          indexIndicatorSep: '-',
        },
      }),
      ProgressbarModule.forRoot(),
    ]),
    provideHttpClient(withInterceptors([authInterceptor])),
    graphqlProvider,
  ],
};
