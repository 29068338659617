import { Component } from '@angular/core';

@Component({
  selector: 'app-diamonds',
  standalone: true,
  imports: [],
  templateUrl: './diamonds.component.html',
  styleUrl: './diamonds.component.scss',
})
export class DiamondsComponent {}
