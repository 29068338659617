<div class="container-fluid mobile-bottom-space">
  <div class="row flex-xl-nowrap px-0">
    <div class="d-none d-lg-block col-xl-3 ontop" style="width: 352px">
      <app-sidebar></app-sidebar>
    </div>
    <div
      class="col-xl-9 col-lg-6 flex-fill col-12 bg-white px-xl-5 px-lg-0 px-3"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
